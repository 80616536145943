
import importComponents from '@/utils/import-components';
import {
  defineComponent, onBeforeMount, reactive, ref,
} from 'vue';
import { useStore } from 'vuex';
import {
  Divider, Form, Button,
} from 'ant-design-vue';
import { useForm } from '@ant-design-vue/use';
import { Alert } from '@/components/Alert/index';
import {
  register, login, sendEmailCode, forgotPassword, otherLogin, otherLoginToken,
} from '@/services/user';
import { useRouter, useRoute } from 'vue-router';
import Cookies from 'js-cookie';
import Input from '@/components/common/Input.vue';
import { useI18n } from 'vue-i18n';
import PrivacyPolicy from '@/components/footerlink/PrivacyPolicy.vue';

interface Form {
  name: string;
  message: string;
}

export default defineComponent({
  name: 'Login',
  components: {
    Input,
    PrivacyPolicy,
    ...importComponents(
      Divider,
      Form,
      Form.Item,
      Button,
    ),
  },
  props: {
    isModal: {
      required: true,
      type: Boolean,
    },
  },
  setup(props, ctx) {
    const { t } = useI18n();

    const router = useRouter();
    const route = useRoute();
    onBeforeMount(() => {
      if (Cookies.get('token')) {
        router.push('/');
      }
    });
    const { dispatch } = useStore();
    // 右边表单的切换，0：登录，1：忘记密码发邮箱验证码，2：改新密码
    const forgetPassword = ref(0);
    const registerForm = reactive({
      // 'First Name': '',
      // 'Last Name': '',
      Email: '',
      Password: '',
    });
    const loginForm = reactive({
      Email: '',
      Password: '',
    });
    const forgotForm = reactive({
      Email: '',
    });
    const resetForm = reactive({
      'Verification Code': '',
      'New Password': '',
    });
    const registerMessage = reactive({
      'First Name': [
        {
          required: true,
          message: t('checkout.enterA') + t('user.firstName'),
        },
      ],
      'Last Name': [
        {
          required: true,
          message: t('checkout.enterA') + t('user.lastName'),
        },
      ],
      Email: [
        {
          trigger: 'change',
          required: true,
          pattern: /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/,
          message: t('user.emailMessage'),

        },
      ],
      Password: [
        {
          required: true,
          min: 6,
          trigger: 'blur',
          message: t('user.passwordMessage'),
        },
      ],
    });
    const loginMessage = reactive({
      Email: [
        {
          trigger: 'change',
          required: true,
          pattern: /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/,
          message: t('user.emailMessage'),
        },
      ],
      Password: [
        {
          required: true,
          min: 6,
          trigger: 'blur',
          message: t('user.passwordMessage'),
        },
      ],
    });
    const forgotMessage = reactive({
      Email: [
        {
          trigger: 'change',
          required: true,
          pattern: /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/,
          message: t('user.forgotMessage'),
        },
      ],
    });
    const resetMessage = reactive({
      'Verification Code': [
        {
          required: true,
          message: t('user.verificationCodeMessage'),
        },
      ],
      'New Password': [
        {
          required: true,
          min: 6,
          trigger: 'blur',
          message: t('user.newPasswordMessage'),
        },
      ],
    });

    const registerLoading = ref(false);
    const loginLoading = ref(false);
    const forgotLoading = ref(false);
    const resetLoading = ref(false);

    // 记录忘记密码流程的邮箱
    const forgotEmail = ref('');

    const {
      validate: registerValidate,
      validateInfos: registerValidateInfos,
    } = useForm(registerForm, registerMessage);
    const {
      validate: loginValidate,
      validateInfos: loginValidateInfos,
    } = useForm(loginForm, loginMessage);
    const {
      validate: forgotValidate,
      validateInfos: forgotValidateInfos,
    } = useForm(forgotForm, forgotMessage);
    const {
      validate: resetValidate,
      validateInfos: resetValidateInfos,
    } = useForm(resetForm, resetMessage);

    // 提取登录流程，注册成功直接登录，isRegister来判断是否弹登录成功的弹框
    const logIn = (email: string, password: string, isRegister: boolean) => {
      const data = {
        account: email,
        password,
      };
      login(data).then((response) => {
        if (response.success) {
          dispatch('getUser');
          dispatch('firstLoginToCart');
          loginLoading.value = false;
          if (!isRegister) {
            const modal = Alert.success(t('user.loginSuccessful'));
            if (props.isModal) {
              // 弹框的登录
              ctx.emit('close');
              setTimeout(() => {
                modal.destroy();
                // 登录成功，获取用户的购物车
                dispatch('getCartItems');
              }, 2000);
            } else {
              setTimeout(() => {
                modal.destroy();
                if (route.query.form) {
                  router.push('/checkout?f=l');
                  return;
                }
                router.push('/');
              }, 2000);
            }
          } else {
            if (route.query.form) {
              router.push('/checkout?f=l');
              return;
            }
            router.push('/');
          }
          dispatch('getUserCartNum');
          localStorage.removeItem('touristToken');
        }
      }).catch(() => {
        const modal = Alert.error(t('user.notMatchMessage'));
        setTimeout(() => modal.destroy(), 2000);
        loginLoading.value = false;
      });
    };

    const newCustomers = async () => {
      const res = await registerValidate();
      if (res) {
        registerLoading.value = true;
        const dataForRegister = {
          userEmail: res.Email,
          userFirstName: res['First Name'],
          userLastName: res['Last Name'],
          userPassword: res.Password,
          token: localStorage.getItem('touristToken') as string,
        };
        register(dataForRegister).then((response) => {
          if (response.success) {
            const modal = Alert.success(t('user.registerSuccessful'));
            setTimeout(() => {
              modal.destroy();
              logIn(res.Email, res.Password, true);
            }, 2000);
            localStorage.removeItem('touristToken');
          } else {
            const modal = Alert.error(response.message);
            setTimeout(() => modal.destroy(), 2000);
          }
          registerLoading.value = false;
        }).catch(() => {
          const modal = Alert.error(t('user.registerUnsuccessful'));
          setTimeout(() => modal.destroy(), 2000);
          registerLoading.value = false;
        });
      }
    };

    const signIn = async () => {
      const res = await loginValidate();
      if (res) {
        loginLoading.value = true;
        logIn(res.Email, res.Password, false);
      }
    };

    const forgot = () => {
      forgetPassword.value = +!forgetPassword.value;
    };

    const toReset = async () => {
      const res = await forgotValidate();
      if (res) {
        forgotLoading.value = true;
        forgotEmail.value = res.Email;
        const data = {
          email: res.Email,
        };
        sendEmailCode(data).then((response) => {
          if (response.success) {
            const modal = Alert.success(`${t('user.sendEmailCodeMessage1')}
                        ${res.Email}. ${t('user.sendEmailCodeMessage2')}`);
            setTimeout(() => modal.destroy(), 2000);
            forgetPassword.value = 2;
          } else {
            const modal = Alert.error(response.message);
            setTimeout(() => modal.destroy(), 2000);
          }
          forgotLoading.value = false;
        }).catch(() => {
          const modal = Alert.error(t('user.submitUnsuccessful'));
          setTimeout(() => modal.destroy(), 2000);
          forgotLoading.value = false;
        });
      }
    };

    const reset = async () => {
      const res = await resetValidate();
      if (res) {
        resetLoading.value = true;
        const data = {
          code: res['Verification Code'],
          email: forgotEmail.value,
          newPassword: res['New Password'],
        };
        forgotPassword(data).then((response) => {
          if (response.success) {
            const modal = Alert.success(t('user.resetSuccessful'));
            setTimeout(() => modal.destroy(), 2000);
            forgetPassword.value = 0;
          } else {
            const modal = Alert.error(response.message);
            setTimeout(() => modal.destroy(), 2000);
          }
          resetLoading.value = false;
        }).catch(() => {
          const modal = Alert.error(t('user.resetUnsuccessful'));
          setTimeout(() => modal.destroy(), 2000);
          resetLoading.value = false;
        });
      }
    };
    // 忘记密码重置点取消回到登录
    const returnLogin = () => {
      forgetPassword.value = 0;
    };

    const othersLogin = async (type: string) => {
      const res = await otherLogin(type);
      if (res.success) {
        const { renderAuthState } = res.data;
        const newwindow = window.open(
          res.data.renderAuthUrl,
          'newwindow',
          'height=500, width=500',
        );

        const loop = setInterval(async () => {
          if (newwindow?.closed) {
            clearInterval(loop);
            const response = await otherLoginToken(renderAuthState);
            if (response.success) {
              Cookies.set('token', response.data, { expires: 1 / 3 });
              router.push('/');
              setTimeout(() => {
                dispatch('getUser');
                dispatch('firstLoginToCart');
                dispatch('getUserCartNum');
              });
              localStorage.removeItem('touristToken');
            }
          }
        });
      }
    };

    return {
      forgetPassword,
      forgot,
      newCustomers,
      registerForm,
      loginForm,
      forgotForm,
      resetForm,
      registerValidateInfos,
      loginValidateInfos,
      forgotValidateInfos,
      resetValidateInfos,
      signIn,
      toReset,
      reset,
      registerLoading,
      loginLoading,
      forgotLoading,
      resetLoading,
      t,
      returnLogin,
      othersLogin,
    };
  },
});
