<template>
    <div class="login-container">
        <div class="title" v-if="!isModal">{{ t('user.customerLogin') }}</div>
        <div class="content">
            <div class="register">
                <div class="content-title">{{ t('user.newCustomers') }}</div>
                <a-form layout="vertical"  :model="registerForm"
                    @submit="newCustomers">
                    <!-- <a-form-item :label="`${t('user.firstName')}`" v-bind="registerValidateInfos['First Name']">
                        <InputA v-model:value="registerForm['First Name']" :clear="true"/>
                    </a-form-item>
                    <a-form-item :label="`${t('user.lastName')}`" v-bind="registerValidateInfos['Last Name']">
                        <InputA v-model:value="registerForm['Last Name']" :clear="true"/>
                    </a-form-item> -->
                    <a-form-item :label="`${t('user.email')}`" v-bind="registerValidateInfos.Email">
                        <Input v-model:value="registerForm.Email" :clear="true"/>
                    </a-form-item>
                    <a-form-item :label="`${t('user.password')}`" v-bind="registerValidateInfos.Password">
                        <Input v-model:value="registerForm.Password" :clear="true" type="password"/>
                    </a-form-item>
                    <a-button type="primary" html-type="submit" :loading="registerLoading">{{ t('user.createAnAccount') }}</a-button>
                </a-form>
            </div>
            <a-divider type="vertical"/>
            <div class="content-login" v-if="!forgetPassword">
                <div class="content-title">{{ t('user.login') }}</div>
                <a-form layout="vertical"  :model="loginForm" @submit="signIn">
                    <a-form-item :label="`${t('user.email')}`" v-bind="loginValidateInfos.Email">
                        <Input v-model:value="loginForm.Email" :clear="true"/>
                    </a-form-item>
                    <a-form-item :label="`${t('user.password')}`" v-bind="loginValidateInfos.Password">
                        <Input v-model:value="loginForm.Password" :clear="true" type='password'/>
                    </a-form-item>
                    <a-button type="primary" html-type="submit" :loading="loginLoading">{{ t('user.signIn') }}</a-button>
                </a-form>
                <div class="forgot">
                    <router-link to="#" @click="forgot">{{ t('user.forgotYourPassword') + '?'}}</router-link>
                </div>
                <a-divider class="or-divider">{{ t('user.or') }}</a-divider>
                <div class="other-login">
                  <a-button shape="round" @click="othersLogin('google')">
                    <template #icon><img src="@/assets/icons/login/google.png" alt="google-login"/></template>
                    Google
                  </a-button>
                  <a-button shape="round" @click="othersLogin('facebook')" class="facebook">
                    <template #icon><img src="@/assets/icons/login/facebook.png" alt="facebook-login"/></template>
                    Facebook
                  </a-button>
                </div>
                <PrivacyPolicy/>
            </div>
            <div class="content-login forgot-password" v-else-if="forgetPassword === 1">
                <div class="content-title">{{ t('user.passwordReset') }}</div>
                <a-form layout="vertical"  :model="forgotForm" @submit="toReset">
                    <a-form-item :label="`${t('user.email')}`" v-bind="forgotValidateInfos.Email">
                        <Input v-model:value="forgotForm.Email" :clear="true"/>
                    </a-form-item>
                    <div class="btn">
                        <a-button type="primary" html-type="submit" :loading="forgotLoading">{{ t('user.submit') }}</a-button>
                        <a-button type="primary" @click="forgot" class="cancel">{{ t('user.cancel') }}</a-button>
                    </div>
                </a-form>
            </div>
            <div class="content-login" v-else>
                <div class="content-title">{{ t('user.resetYourPassword') }}</div>
                <a-form layout="vertical"  :model="resetForm" @submit="reset">
                    <a-form-item :label="`${t('user.verificationCode')}`"
                        v-bind="resetValidateInfos['Verification Code']">
                        <Input v-model:value="resetForm['Verification Code']" :clear="true"/>
                    </a-form-item>
                    <a-form-item :label="`${t('user.newPassword')}`" v-bind="resetValidateInfos['New Password']">
                        <Input v-model:value="resetForm['New Password']" :clear="true" type='password'/>
                    </a-form-item>
                    <div class="btn">
                        <a-button type="primary" html-type="submit" :loading="resetLoading">{{ t('user.resetPassword') }}</a-button>
                        <a-button type="primary" @click="returnLogin" class="cancel">{{ t('user.cancel') }}</a-button>
                    </div>
                </a-form>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import importComponents from '@/utils/import-components';
import {
  defineComponent, onBeforeMount, reactive, ref,
} from 'vue';
import { useStore } from 'vuex';
import {
  Divider, Form, Button,
} from 'ant-design-vue';
import { useForm } from '@ant-design-vue/use';
import { Alert } from '@/components/Alert/index';
import {
  register, login, sendEmailCode, forgotPassword, otherLogin, otherLoginToken,
} from '@/services/user';
import { useRouter, useRoute } from 'vue-router';
import Cookies from 'js-cookie';
import Input from '@/components/common/Input.vue';
import { useI18n } from 'vue-i18n';
import PrivacyPolicy from '@/components/footerlink/PrivacyPolicy.vue';

interface Form {
  name: string;
  message: string;
}

export default defineComponent({
  name: 'Login',
  components: {
    Input,
    PrivacyPolicy,
    ...importComponents(
      Divider,
      Form,
      Form.Item,
      Button,
    ),
  },
  props: {
    isModal: {
      required: true,
      type: Boolean,
    },
  },
  setup(props, ctx) {
    const { t } = useI18n();

    const router = useRouter();
    const route = useRoute();
    onBeforeMount(() => {
      if (Cookies.get('token')) {
        router.push('/');
      }
    });
    const { dispatch } = useStore();
    // 右边表单的切换，0：登录，1：忘记密码发邮箱验证码，2：改新密码
    const forgetPassword = ref(0);
    const registerForm = reactive({
      // 'First Name': '',
      // 'Last Name': '',
      Email: '',
      Password: '',
    });
    const loginForm = reactive({
      Email: '',
      Password: '',
    });
    const forgotForm = reactive({
      Email: '',
    });
    const resetForm = reactive({
      'Verification Code': '',
      'New Password': '',
    });
    const registerMessage = reactive({
      'First Name': [
        {
          required: true,
          message: t('checkout.enterA') + t('user.firstName'),
        },
      ],
      'Last Name': [
        {
          required: true,
          message: t('checkout.enterA') + t('user.lastName'),
        },
      ],
      Email: [
        {
          trigger: 'change',
          required: true,
          pattern: /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/,
          message: t('user.emailMessage'),

        },
      ],
      Password: [
        {
          required: true,
          min: 6,
          trigger: 'blur',
          message: t('user.passwordMessage'),
        },
      ],
    });
    const loginMessage = reactive({
      Email: [
        {
          trigger: 'change',
          required: true,
          pattern: /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/,
          message: t('user.emailMessage'),
        },
      ],
      Password: [
        {
          required: true,
          min: 6,
          trigger: 'blur',
          message: t('user.passwordMessage'),
        },
      ],
    });
    const forgotMessage = reactive({
      Email: [
        {
          trigger: 'change',
          required: true,
          pattern: /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/,
          message: t('user.forgotMessage'),
        },
      ],
    });
    const resetMessage = reactive({
      'Verification Code': [
        {
          required: true,
          message: t('user.verificationCodeMessage'),
        },
      ],
      'New Password': [
        {
          required: true,
          min: 6,
          trigger: 'blur',
          message: t('user.newPasswordMessage'),
        },
      ],
    });

    const registerLoading = ref(false);
    const loginLoading = ref(false);
    const forgotLoading = ref(false);
    const resetLoading = ref(false);

    // 记录忘记密码流程的邮箱
    const forgotEmail = ref('');

    const {
      validate: registerValidate,
      validateInfos: registerValidateInfos,
    } = useForm(registerForm, registerMessage);
    const {
      validate: loginValidate,
      validateInfos: loginValidateInfos,
    } = useForm(loginForm, loginMessage);
    const {
      validate: forgotValidate,
      validateInfos: forgotValidateInfos,
    } = useForm(forgotForm, forgotMessage);
    const {
      validate: resetValidate,
      validateInfos: resetValidateInfos,
    } = useForm(resetForm, resetMessage);

    // 提取登录流程，注册成功直接登录，isRegister来判断是否弹登录成功的弹框
    const logIn = (email: string, password: string, isRegister: boolean) => {
      const data = {
        account: email,
        password,
      };
      login(data).then((response) => {
        if (response.success) {
          dispatch('getUser');
          dispatch('firstLoginToCart');
          loginLoading.value = false;
          if (!isRegister) {
            const modal = Alert.success(t('user.loginSuccessful'));
            if (props.isModal) {
              // 弹框的登录
              ctx.emit('close');
              setTimeout(() => {
                modal.destroy();
                // 登录成功，获取用户的购物车
                dispatch('getCartItems');
              }, 2000);
            } else {
              setTimeout(() => {
                modal.destroy();
                if (route.query.form) {
                  router.push('/checkout?f=l');
                  return;
                }
                router.push('/');
              }, 2000);
            }
          } else {
            if (route.query.form) {
              router.push('/checkout?f=l');
              return;
            }
            router.push('/');
          }
          dispatch('getUserCartNum');
          localStorage.removeItem('touristToken');
        }
      }).catch(() => {
        const modal = Alert.error(t('user.notMatchMessage'));
        setTimeout(() => modal.destroy(), 2000);
        loginLoading.value = false;
      });
    };

    const newCustomers = async () => {
      const res = await registerValidate();
      if (res) {
        registerLoading.value = true;
        const dataForRegister = {
          userEmail: res.Email,
          userFirstName: res['First Name'],
          userLastName: res['Last Name'],
          userPassword: res.Password,
          token: localStorage.getItem('touristToken') as string,
        };
        register(dataForRegister).then((response) => {
          if (response.success) {
            const modal = Alert.success(t('user.registerSuccessful'));
            setTimeout(() => {
              modal.destroy();
              logIn(res.Email, res.Password, true);
            }, 2000);
            localStorage.removeItem('touristToken');
          } else {
            const modal = Alert.error(response.message);
            setTimeout(() => modal.destroy(), 2000);
          }
          registerLoading.value = false;
        }).catch(() => {
          const modal = Alert.error(t('user.registerUnsuccessful'));
          setTimeout(() => modal.destroy(), 2000);
          registerLoading.value = false;
        });
      }
    };

    const signIn = async () => {
      const res = await loginValidate();
      if (res) {
        loginLoading.value = true;
        logIn(res.Email, res.Password, false);
      }
    };

    const forgot = () => {
      forgetPassword.value = +!forgetPassword.value;
    };

    const toReset = async () => {
      const res = await forgotValidate();
      if (res) {
        forgotLoading.value = true;
        forgotEmail.value = res.Email;
        const data = {
          email: res.Email,
        };
        sendEmailCode(data).then((response) => {
          if (response.success) {
            const modal = Alert.success(`${t('user.sendEmailCodeMessage1')}
                        ${res.Email}. ${t('user.sendEmailCodeMessage2')}`);
            setTimeout(() => modal.destroy(), 2000);
            forgetPassword.value = 2;
          } else {
            const modal = Alert.error(response.message);
            setTimeout(() => modal.destroy(), 2000);
          }
          forgotLoading.value = false;
        }).catch(() => {
          const modal = Alert.error(t('user.submitUnsuccessful'));
          setTimeout(() => modal.destroy(), 2000);
          forgotLoading.value = false;
        });
      }
    };

    const reset = async () => {
      const res = await resetValidate();
      if (res) {
        resetLoading.value = true;
        const data = {
          code: res['Verification Code'],
          email: forgotEmail.value,
          newPassword: res['New Password'],
        };
        forgotPassword(data).then((response) => {
          if (response.success) {
            const modal = Alert.success(t('user.resetSuccessful'));
            setTimeout(() => modal.destroy(), 2000);
            forgetPassword.value = 0;
          } else {
            const modal = Alert.error(response.message);
            setTimeout(() => modal.destroy(), 2000);
          }
          resetLoading.value = false;
        }).catch(() => {
          const modal = Alert.error(t('user.resetUnsuccessful'));
          setTimeout(() => modal.destroy(), 2000);
          resetLoading.value = false;
        });
      }
    };
    // 忘记密码重置点取消回到登录
    const returnLogin = () => {
      forgetPassword.value = 0;
    };

    const othersLogin = async (type: string) => {
      const res = await otherLogin(type);
      if (res.success) {
        const { renderAuthState } = res.data;
        const newwindow = window.open(
          res.data.renderAuthUrl,
          'newwindow',
          'height=500, width=500',
        );

        const loop = setInterval(async () => {
          if (newwindow?.closed) {
            clearInterval(loop);
            const response = await otherLoginToken(renderAuthState);
            if (response.success) {
              Cookies.set('token', response.data, { expires: 1 / 3 });
              router.push('/');
              setTimeout(() => {
                dispatch('getUser');
                dispatch('firstLoginToCart');
                dispatch('getUserCartNum');
              });
              localStorage.removeItem('touristToken');
            }
          }
        });
      }
    };

    return {
      forgetPassword,
      forgot,
      newCustomers,
      registerForm,
      loginForm,
      forgotForm,
      resetForm,
      registerValidateInfos,
      loginValidateInfos,
      forgotValidateInfos,
      resetValidateInfos,
      signIn,
      toReset,
      reset,
      registerLoading,
      loginLoading,
      forgotLoading,
      resetLoading,
      t,
      returnLogin,
      othersLogin,
    };
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/styles/mixin.scss";
@import '@/assets/styles/variables.scss';

.login-container {
    @include container();
    .title {
        color: $theme-color;
        font-size: 25px;
        font-weight: bold;
        padding: 30px;
    }
    .content {
        width: 57%;
        min-width: 979px;
        padding: 40px 90px 70px;
        display: flex;
        margin: auto;
        .content-title {
            font-size: 18px;
            color: $font-color;
            font-weight: $font-weight;
        }
        .ant-form {
            padding-top: 30px;
        }
        p {
            font-size: $font-size;
            color: #545454;
            line-height: 18px;
            margin: 17px 0;
        }
        :deep(.input-style){
            width: 312px;
        }

        .ant-divider, .ant-divider-vertical {
            height: 553px;
            color: #D7D7D7;
            margin: unset;
        }
        .register {
            width: 40%;
            margin: 0 10% 0 0;
        }
        .ant-form-vertical .ant-form-item-label > label {
            font-size: 18px;
            color: #545454;
            font-weight: 400;
            line-height: 20px;
        }
        .ant-form-item-control-wrapper {
            border: 2px solid #CECECE;
            border-radius: 2px;
        }
        .ant-input-affix-wrapper-focused {
            border-color: $theme-color;
            box-shadow: $box-shadow;
        }
        .ant-btn-primary {
            font-size: 16px;
            font-weight: bold;
            line-height: 24px;
            width: 100%;
            height: 44px;
            border-radius: 4px;
            background-color: $theme-color;
            border-color: $theme-color;
        }
        .content-login {
            width: 40%;
            margin-left: 11%;
            .forgot {
                text-align: end;
                padding-top: 12px;
                a {
                    color: #969696;
                    font-weight: 500;
                    text-decoration: underline;
                    &:hover {
                        color: $theme-color;
                    }
                }
            }
            .or-divider {
              height: unset;
              color: #9F9F9F;
              font-size: 15px;
              font-weight: 600;
              padding: 23px 0;
              &::before, &::after {
                border-top: 2px solid #E1E1E1;
              }
            }
            .other-login {
              display: flex;
              justify-content: space-between;
              // justify-content: center;
              .ant-btn-round {
                width: 150px;
                height: 50px;
                background: #FAFAFA;
                border: none;
                margin-right: unset;
                img {
                  width: 16px;
                  margin-right: 15px;
                }
              }
              .facebook {
                background-color: #486AB1;
                color: #FFFFFF;
                img {
                  width: 12px;
                }
              }
            }
        }
        .forgot-password, .content-login {
            .ant-btn, .ant-btn-primary {
                // width: 46%;
                margin-right: 15px
            }
            .btn {
                display: flex;
                justify-content: space-between;
                .cancel {
                    background-color: #969696;
                    border-color: #969696;
                }
            }
        }
    }
}
</style>
